<template>
  <div id="component-product-summary" class="pb-2">
    <article>
      <div class="media left">
        <div class="media-left">
          <a class="image is-64x64"
                :href="'/products/' + product.short"
                aria-label="view article"
              >
            <img
              :src="
                product.iconUrl == null
                  ? 'https://bulma.io/images/placeholders/128x128.png'
                  : product.iconUrl
              "
              alt="Image"
            />
          </a>
        </div>
        <div class="media-content">
          <div class="content">
            <div class="title is-5"><a 
            class="has-text-black"
              :href="'/products/' + product.short">{{ product.name }}</a></div>
            <span v-html="compileMarkdown(product.summary)"></span>
          </div>
          <div class="level is-mobile">
            <div class="level-left">
              <small class="level-item">{{
                formatDate(product.updated)
              }}</small>
            </div>
            <div class="level-right">
              <a
                class="level-item left"
                :href="'/products/' + product.short"
                aria-label="view article"
              >
                <span class="icon is-small">
                  <i class="fas fa-search" aria-hidden="true"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import Marked from "marked";

export default {
  name: "component.product.summary",
  props: {
    product: Object,
    session: Object
  },
  methods: {
    formatDate(str) {
      var d = new Date(str);
      return (
        d.toLocaleDateString("en-US") + "  " + d.toLocaleTimeString("en-US")
      );
    },
    compileMarkdown(str) {
      return Marked(str);
    },
    iconFor(product) {
      return product.iconUrl == null
        ? "https://bulma.io/images/placeholders/128x128.png"
        : "/api/asset/content/" + product.iconUrl;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@media screen and (min-width: 1px) {
  #component-product-summary {
    article {
      margin-bottom: 8px;
    }
    .media-content {
      font-size: 0.9em;
    }
  }
}
@media screen and (min-width: 767px) {
  #component-product-summary {
    article {
      margin-bottom: 10px;
    }
    .media-content {
      font-size: 1em;
    }
  }
}
</style>
