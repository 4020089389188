<template>
  <div class="view-products">
    <div class="hero is-success" v-if="showHeader">
      Product Alerts
    </div>
    <section>
      <!-- maybe product tag list -->
    </section>

    <div v-for="item in list" :key="list.indexOf(item)">
      <Summary :session="session" :product="item"></Summary>
    </div>
  </div>
</template>

<script>
import Products from "@/Druware.Web.Shared/api/api";
import Summary from "@/Druware.Web.Shared/components/ProductSummary";

const url = "/api/product/";

export default {
  name: "view.products",
  components: {
    Summary
  },
  data: function() {
    return {
      session: {},
      list: [],
      showHeader: false,
      activeTab: ""
    };
  },

  methods: {
    showError(err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: err,
        position: "is-bottom",
        type: "is-danger"
      });
    }
  },
  mounted() {
    this.session = this.$attrs.session;
    Products.list(
      url,
      this,
      function(obj, data) {
        obj.list = data.list;
      },
      function(obj, err) {
        obj.showError(err);
      }
    );
  }
};
</script>
